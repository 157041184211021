:root {
  /* --primary-color: #CCAC00; */
  --primary-color: #ffc745;
  --typography-primary: #161a1e;
  --typography-secondary: #333;
  --typography-accent-1: #927001;
  --succss-green: #006a4e;
  --error-red: #bc3f4a;
  --clickable-link: #2979ff;
  --bgcolor-1: #f2f2f2;

  /* font families */
  --si-font-family: "Noto Sans Sinhala Variable", sans-serif;
}

* {
  font-family: "Ubuntu", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch;
}

.MuiInputBase-root,
.MuiTypography-root,
.MuiFormLabel-root {
  font-family: "Ubuntu", sans-serif !important;
}

.sinhala-typo {
  font-family: "Noto Sans Sinhala Variable", sans-serif !important;
}

.typo-si-body-1 {
  font-family: "Noto Sans Sinhala Variable", sans-serif !important;
  font-size: 14px;
}

.bg-page-container {
  background-color: #dfdfdf;
}

.bg-page-container-2 {
  background-color: var(--bgcolor-1);
}

.bg-white {
  background-color: white !important;
}

/* page-layout */
.page-layout {
  background-color: #dfdfdf;
}

.page-content {
  max-width: 960px;
  border-radius: 8px;
  /* padding: 16px 16px; */
  padding: 16px 36px;
}

@media (max-width: 768px) {
  .page-layout {
    padding-left: 15px;
    padding-right: 15px;
  }
  .page-content {
    padding: 16px;
  }
}

/* New Design System initiatives */
/* FlexBox Properties */
.app-root-container {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}

.flex-1 {
  flex: 1 1 0%;
}
.row-gap-8 {
  row-gap: 8px;
}

.row-gap-4 {
  row-gap: 4px;
}

.row-gap-16 {
  row-gap: 16px;
}

.row-gap-32 {
  row-gap: 32px;
}

.column-gap-4 {
  column-gap: 4px;
}

.column-gap-8 {
  column-gap: 8px;
}

.column-gap-16 {
  column-gap: 16px;
}
.margin-top-8 {
  margin-top: 8px !important;
}

.margin-right-8 {
  margin-right: 8px !important;
}

.margin-top-16 {
  margin-top: 16px !important;
}

.margin-top-24 {
  margin-top: 24px !important;
}

.margin-bottom-8 {
  margin-bottom: 8px !important;
}

.margin-bottom-16 {
  margin-bottom: 16px !important;
}

.p-16 {
  padding: 16px;
}

.padding-top-32 {
  padding-top: 32px;
}

.padding-block-32 {
  padding-block: 32px;
}

.padding-block-16 {
  padding-block: 16px;
}

.padding-inline-16 {
  padding-inline: 16px;
}
.padding-2 {
  padding: 2px !important;
}

.pt-12 {
  padding-top: 12px;
}

.pt-8 {
  padding-top: 8px;
}

.pt-4 {
  padding-top: 4px;
}

.cursor-pointer {
  cursor: pointer;
}

.padding-bottom-32 {
  padding-bottom: 32px !important;
}

.padding-32 {
  padding: 32px
}

.text-center {
  text-align: center;
}

/* Overflow Properties */
.overflow-hidden {
  overflow: hidden !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-truncate-2-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* Font weights */
.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-color-success {
  color: var(--succss-green) !important;
}

/* Typography definitions */
.capitalized-text {
  text-transform: capitalize !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-bold {
  font-weight: 600 !important;
}

.text-semi-bold {
  font-weight: 500 !important;
}

.text-center {
  text-align: center;
}

.text-error {
  color: var(--error-red) !important;
}

.accent-1-text {
  color: var(--typography-accent-1);
}

/* Typography h2 */

.typo-h2-en {
  font-family: inherit;
  font-weight: 400 !important;
  font-size: 20px !important;
}

.typo-h2-si {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: var(--si) !important;
}

/* Typography h3 */
.typo-h3-en {
  font-size: 18px !important;
  font-family: inherit;
}

.typo-h3-si {
  font-family: var(--si) !important;
  font-size: 16px !important;
}

.typo-h4-en {
  font-size: 16px !important;
  font-family: inherit;
}

.typo-h4-si {
  font-family: var(--si) !important;
  font-size: 14px !important;
}

.typo-h5-en {
  font-size: 14px !important;
  font-family: inherit;
}

.typo-h5-si {
  font-family: var(--si) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.typo-body1-en {
  font-size: 14px !important;
  font-family: inherit;
}

.typo-body1-si {
  font-family: "Noto Sans Sinhala Variable", sans-serif !important;
  font-size: 13px !important;
}

.form-element-container {
  width: 50%;
}

.form-item-label {
  width: 30%;
  font-size: 12px !important;
  text-align: right;
  font-weight: 400 !important;
  line-height: 16px;
  color: var(--typography-primary) !important;
}

.form-item-label-si {
  font-size: 10px !important;
  font-family: var(--si-font-family) !important;
}

.form-item-label-top {
  text-align: left !important;
  width: 100% !important;
  margin-bottom: 8px !important;
}

/* Button styles */

.primary-button {
  background-color: #fccd12 !important;
  color: var(--typography-primary);
}

.secondary-button {
  background-color: #000 !important;
  color: #fff !important;
}

/* mobile responsive */
@media only screen and (max-width: 600px) {
  .form-element-container {
    width: 100% !important;
  }

  .form-item-label {
    width: 25%;
  }
}

@import url("./v2/styles/main.css");
