.page-section {
  background-color: #fff;
}

.setting-btn-primary {
  min-width: 120px !important;
  background-color: #000 !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

.setting-btn-secondary {
  min-width: 120px !important;
  background-color: #e7e7e7 !important;
  color: #292929 !important;
  text-transform: capitalize !important;
}

.ad-remove-btn {
  width: 120px;
  background-color: #d13333 !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

.setting-btn-secondary:disabled {
  background-color: #e7e7e7 !important;
  color: #999 !important;
}

.setting-btn-primary:disabled {
  background-color: #969696 !important;
  color: #d3d3d3 !important;
}

.page-section .notification-type-caption {
  font-size: 12px;
  color: #6c757d;
}

.page-section .MuiTypography-caption {
  font-size: 13px !important;
}

.page-section .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #ffc745;
}

.page-section .MuiSwitch-colorSecondary.Mui-checked {
  color: #ffc745;
}

.page-section .envelope-container {
  position: relative;
  max-width: 100%;
}

.page-section .envelope-address-wrapper {
  position: absolute;
  top: 40%;
  left: 50%;
  right: 40%;
  max-width: 80%;
  overflow-wrap: break-word;
}

@media only screen and (max-width: 900px) {
  .page-section .preferences-toggle-txt {
    width: 75%;
  }
}
