.post-details {
  position: relative;
  width: 100%;
  background: rgb(255, 255, 255);
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.post-details .post-details-col {
  display: inline-block;
  width: 100%;
  padding-top: 20px;
}

.post-details .post-date-col {
  position: absolute;
  display: inline-block;
  width: 150px;
  right: 15px;
}

.post-details .post-details-col .applicant-photo {
  width: 30px;
  background: #e8e8e8;
  border-radius: 50%;
  padding: 5px;
}

.post-details .post-details-col .name {
  display: inline-block;
  color: rgb(92, 92, 92);
  line-height: unset;
  margin-bottom: 5px;
  margin-left: 13px;
  vertical-align: top;
  width: 75%;
  overflow: hidden;
  height: 24px;
}

.createdby-label {
  display: block;
  color: #ffffff;
  font-size: 11px;
  line-height: 10px;
  padding: 6px 9px;
  background: #4f4f4f;
  width: fit-content;
  border-radius: 24px;
  margin-left: 71px;
  margin-top: 5px;
}

.post-details .post-details-col .location {
  margin: 0;
  color: rgb(53, 53, 53);
  font-size: 13px;
  margin-left: 54px;
  margin-top: -17px;
}

.post-details .post-date-col .date-of-post {
  margin: 0;
  font-size: 12px;
  color: #7b7b7b;
}

.post-details .applicant-info-col {
  display: inline-block;
  width: 70%;
  margin-top: 15px;
  color: rgb(73, 73, 73);
  font-size: 14px;
}

.post-details .applicant-info-col .info {
  display: inline-block;
  width: 31%;
  margin: 1%;
}

.post-details .applicant-info-col .info .icon {
  width: 15px;
  text-align: center;
}

.post-details .post-interest-col {
  display: inline-block;
  width: 170px;
}

.post-details .post-interest-col .interest-btn {
  float: right;
  color: #fff;
  background: #000;
  font-weight: 500;
}

.app-store-div {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 50px;
}

.hytch-label {
  display: block;
  color: #ffffff;
  font-size: 11px;
  line-height: 10px;
  padding: 6px 9px;
  background: #832ab0;
  width: fit-content;
  border-radius: 24px;
  margin-left: 71px;
  margin-top: -21px;
}

@media (max-width: 480px) {
  .post-details .applicant-info-col .info {
    width: 90%;
    margin: 2% 5% !important;
  }

  .app-store-div {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 50px;
    text-align: center;
  }

  #app-store-logo {
    margin-top: 20px;
  }

  #hytch-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .back-btn-in-detail-pages {
    padding-top: 16px !important;
  }
}

@media (min-width: 575px) and (max-width: 860px) {
  .app-store-div {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 50px;
    text-align: center;
  }

  #app-store-logo {
    margin-top: 30px;
  }

  #hytch-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 480px) and (max-width: 959px) {
  .post-details .applicant-info-col .info {
    width: 46%;
    margin: 1% 2% !important;
  }
}

@media (min-width: 960px) and (max-width: 1280px) {
  .post-details .applicant-info-col .info {
    width: 31.3%;
    margin: 1.5% 1% !important;
  }
}

.post-details .desc {
  color: #6b6b6b;
  font-size: 14px;
  line-height: 20px;
  margin-top: 30px;
  margin-left: 15px;
}

.post-details .heading {
  color: #494949;
  line-height: 20px;
  margin-top: 45px;
  margin-bottom: 20px;
}

.post-details .sub-heading {
  color: #6b6b6b;
  font-size: 15px;
  margin: 15px 0 5px 0;
}

.post-details .input-field label {
  color: #8a8a8a;
}

.post-details .input-field input {
  color: #494949;
}

.photos {
  width: 120px;
  height: 120px;
  margin: 25px 15px 0 0;
  object-fit: cover;
  cursor: zoom-in;
  border-radius: 5px;
}

@media (max-width: 480px) {
  .photos {
    margin: 15px 15px 0 0;
  }
}

.interest-btn {
  display: block;
  width: 175px;
  color: #fff !important;
  background: #000 !important;
  font-weight: 500;
  margin-top: 15px !important;
}

.action-btn {
  width: 175px;
  color: #000 !important;
  background: #c19d0d !important;
  font-weight: 500;
  margin-top: 15px !important;
}

.interest-btn-disbled {
  display: block;
  font-weight: 500;
  margin-top: 15px !important;
}

@media (max-width: 430px) {
  .post-details .phone-btn {
    margin-top: 15px;
    margin-left: 15px;
    width: 175px;
  }
}

/* notification */
.MuiSnackbar-anchorOriginTopRight {
  top: 85px !important;
}

/* time line */
.time-line {
  margin-bottom: 30px;
}

.time-line div:nth-child(2) div:nth-child(1) {
  color: #1b1b1b !important;
  font-size: 12px;
}

.only-received div:nth-child(2) div:nth-child(1) {
  font-weight: 500;
  font-size: 15px;
}

.time-line div:nth-child(2) div:nth-child(2) {
  font-weight: 500;
  font-size: 15px;
}

.time-line div:nth-child(3) {
  box-shadow: none !important;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 0 !important;
  margin-top: 5px !important;
  margin-right: 0 !important;
}

.back-btn-in-detail-pages {
  box-shadow: none !important;
  color: rgb(133, 133, 133) !important;
  padding-left: 0 !important;
  font-size: 14px !important;
}

.back-btn-in-detail-pages:hover {
  background: transparent !important;
  color: rgb(99, 99, 99) !important;
}

.invisible-photos {
  width: 100%;
  background: #e0e0e0;
  border-radius: 6px;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  padding: 10px;
  margin: 20px 1%;
  box-sizing: border-box;
}

.report-profile {
  position: absolute;
  bottom: 15px;
  right: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #f2994a;
  text-align: right;
  cursor: pointer;
  margin-bottom: 0;
}

.add-explanation {
  margin-top: 12px;
  margin-bottom: 7px;
  margin-left: 13px;
  display: block;
  color: #333333;
  font-size: 14px;
}

.report-add-desc-input {
  margin-left: 13px;
  width: calc(100% - 25px);
  border: 1px solid #ccc;
  padding: 5px 10px;
}

.report-add-desc-input:focus {
  border: 1px solid #000 !important;
}

.ignore-int-text-btn {
  text-decoration: underline;
  margin: 0;
  cursor: pointer;
}

.received-ignore-btn {
  min-width: 150px !important;
  color: #000 !important;
  background: #fff !important;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  margin-bottom: 10px !important;
}

/* respond */
.respond-ways {
  border-radius: 6px;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-top: 15px;
}

.respond-ways tr td:nth-child(1) {
  width: 40px;
  padding: 0 0 0 15px;
}

.respond-ways tr td:nth-child(2) {
  padding: 0;
}

.respond-ways tr td {
  vertical-align: top;
}

.respond-ways .address {
  margin: 0;
}

.respond-ways .sample-envelop {
  text-decoration: underline;
  cursor: pointer;
}

.respond-ways i {
  display: inline-block;
  font-size: 20px;
  margin-right: 20px;
}

.respond-ways-hidden {
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-top: 15px;
}

.respond-ways-hidden tr td:nth-child(1) {
  width: 40px;
  padding: 10px 0 10px 15px;
}

.respond-ways-hidden tr td:nth-child(2) {
  background: #e0e0e0;
  padding: 10px 15px;
  border-radius: 6px;
}

.respond-ways-hidden i {
  display: inline-block;
  font-size: 20px;
  margin-right: 20px;
}

.received-request-message {
  margin-top: 0 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #f54646;
}

.accept-before-rejection-msg-container {
  display: flex !important;
  align-items: center !important;
  column-gap: 4px !important;
  margin: 4px 0 14px 0;
}

.accept-before-rejection-msg {
  color: #333;
  border-radius: 5px;
}

.message-btn {
  display: block !important;
  min-width: 140px !important;
  margin-top: 0px !important;
  color: #fff !important;
  background: #000 !important;
}

.int-accept-btn {
  display: inline-block !important;
  min-width: 150px !important;
  margin-top: 0px !important;
  color: #fff !important;
  background: #000 !important;
  margin-bottom: 10px !important;
  border: 1px solid #000 !important;
}

.MuiDialog-paper {
  margin: 15px !important;
}

.MuiAlert-standardInfo {
  /* background-color: #fff2d7 !important; */
  color: #333333 !important;
}

.MuiAlert-standardInfo .MuiAlert-icon {
  color: #333333 !important;
}
